import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnDestroy, OnInit, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { groupBy, maxBy, sortBy } from 'lodash';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import moment, { Moment } from 'moment';
import {
  MonitorPhraseResultModel,
  MonitorProductResultModel,
  MonitorResultsForPhraseTaskInterface,
  MonitorResultsForProductTaskInterface,
  MonitorTaskModel,
} from 'src/app/shared/model/monitor.model';
import { MonitorService } from 'src/app/shared/service/monitor.service';
import { ProjectModel } from '../../project.model';
import { RankTypes } from '../monitor.enum';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ChartApexOptions } from '../../../../shared/components/apex-chart/apex-chart.component';
import { ChartType } from '../../../../shared/sem-chart/configuration/sem-chart-config';
import { DateRangeEnum, DateRangeService } from '../../../../shared/service/date-range.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ChartDataPoint, ChartSeries, ChartService } from 'src/app/shared/sem-chart/chart.service';

interface MinMaxData {
  key: string;
  min: number;
  max: number;
}

@Component({
  selector: 'app-task-results-popup',
  templateUrl: './task-results-popup.component.html',
  styleUrls: ['./task-results-popup.component.scss'],
  providers: [ChartService],
})
export class TaskResultsPopupComponent implements OnInit, OnDestroy {
  productTableDataSource!: MonitorProductResultModel[];
  phraseTableDataSource!: MonitorPhraseResultModel[];
  isLoaded = false;
  isProductTask!: boolean;
  isTableLoading: boolean = false;
  isChartLoading: boolean = false;
  task!: MonitorTaskModel;
  type!: RankTypes | undefined;
  taskResults!: MonitorTaskModel[];
  currency!: string;
  displayedProductColumns: string[] = [
    'select',
    'rank_price',
    'seller',
    'price',
    'total_price',
    'shipping_costs',
    'price_min',
    'price_max',
    'url',
  ];

  displayedPhraseColumns: string[] = ['select', 'rank_group', 'rank_min', 'rank_max', 'domain', 'title', 'url'];
  displayedShoppingAdsColumns: string[] = ['select', 'rank_group', 'rank_min', 'rank_max', 'domain', 'price', 'title', 'url'];
  selection = new SelectionModel<MonitorProductResultModel | MonitorPhraseResultModel>(true, []);
  minmaxData!: Record<string, MinMaxData>;
  activeProject!: ProjectModel;

  dataset: [string, string, number][] = [];
  sortedRangeEnum: { key: string; value: DateRangeEnum }[];
  protected activeOptionButton = DateRangeEnum.ThreeMonths;
  protected rangeEnum = DateRangeEnum;
  protected range!: FormGroup<{
    start: FormControl<Moment | null>;
    end: FormControl<Moment | null>;
  }>;

  tableDate!: Moment;
  productResults!: MonitorResultsForProductTaskInterface;
  phraseResults!: MonitorResultsForPhraseTaskInterface;

  protected chartOptions: Partial<ChartApexOptions> = {};
  protected readonly ChartType = ChartType;
  private readonly yAxisBuffer = 1.1;
  private fb = inject(FormBuilder);
  private monitorService = inject(MonitorService);
  private translate = inject(TranslateService);
  private route = inject(ActivatedRoute);
  private dateRangeService = inject(DateRangeService);
  private chartService = inject(ChartService);
  private onDestroy$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<TaskResultsPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { task: MonitorTaskModel; type?: RankTypes },
  ) {
    const { minDate, maxDate } = this.dateRangeService.getDateRange(this.activeOptionButton);
    this.range = this.fb.group({
      start: this.fb.control<Moment | null>(moment(minDate)),
      end: this.fb.control<Moment | null>(moment(maxDate)),
    });

    this.sortedRangeEnum = Object.entries(this.rangeEnum).map(([key, value]) => ({ key, value }));
  }

  ngOnInit(): void {
    this.activeProject = this.route.snapshot.data.project;
    this.task = this.data.task;
    this.type = this.data.type;
    this.isProductTask = !!this.task.monitor_product_id;

    this.getResults();

    this.range.valueChanges
      .pipe(
        takeUntil(this.onDestroy$),
        filter((rangeValues) => !!rangeValues.start && !!rangeValues.end),
        distinctUntilChanged(
          (prev, curr) => moment(prev.start).isSame(moment(curr.start), 'day') && moment(prev.end).isSame(moment(curr.end), 'day'),
        ),
      )
      .subscribe(() => {
        this.getResults(this.range.value.start!, this.range.value.end!);
      });
  }

  getResults(start?: Moment, end?: Moment, updateChart: boolean = true) {
    this.dataset = [];
    if (!updateChart) {
      this.isTableLoading = true;
    } else {
      this.isChartLoading = true;
    }

    if (this.isProductTask) {
      this.monitorService
        .getResultsForProductTask(this.task.id!, start?.toISOString(), end?.toISOString())
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((res) => {
          this.productResults = res;
          this.prepareProductDataSet(updateChart);
        });
    } else {
      this.monitorService
        .getResultsForPhraseTask(this.task.id!, start?.toISOString(), end?.toISOString(), this.type)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((res) => {
          this.phraseResults = res;
          this.preparePhraseDataSet(updateChart);
        });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    let numRows = 0;
    if (this.isProductTask) {
      numRows = this.productTableDataSource.length;
    } else {
      numRows = this.phraseTableDataSource.length;
    }

    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle($event: MatCheckboxChange) {
    if ($event) {
      if (this.isProductTask) {
        this.isAllSelected()
          ? this.selection.clear()
          : this.productTableDataSource.forEach((row: MonitorProductResultModel) => this.selection.select(row));
      } else {
        this.isAllSelected()
          ? this.selection.clear()
          : this.phraseTableDataSource.forEach((row: MonitorPhraseResultModel) => this.selection.select(row));
      }
    }

    this.selectionChange();
  }

  selectionChange() {
    this.updateCharts(true);
  }

  getMinMaxPrice(seller: string, minMax: 'min' | 'max') {
    const sellerR = seller.replace(/\s/g, '');
    return this.minmaxData[sellerR]?.[minMax] || '';
  }

  getRank(): number {
    return this.isProductTask ? this.task.rank_price! : this.task.rank_group!;
  }

  public updateOptions(range: DateRangeEnum): void {
    const { minDate, maxDate } = this.dateRangeService.getDateRange(range);

    this.range.setValue(
      {
        start: minDate ? moment(minDate) : null,
        end: moment(maxDate),
      },
      { emitEvent: false },
    );

    this.activeOptionButton = range;
  }

  updateCharts(reloadChart: boolean = true) {
    const lang = this.translate.currentLang || 'en';
    const dateFormatter = new Intl.DateTimeFormat(lang, { day: '2-digit', month: 'short', year: 'numeric' });

    const minDate = this.range.value.start ? moment(this.range.value.start).valueOf() : undefined;
    const maxDate = this.range.value.end ? moment(this.range.value.end).valueOf() : undefined;

    const filteredDataset = this.dataset.filter((item) => {
      const date = moment(item[0]).valueOf();
      if (minDate && date < minDate) return false;
      return !(maxDate && date > maxDate);
    });

    const groupedData = filteredDataset.reduce(
      (acc: Record<string, Record<number, ChartDataPoint>>, item: [string, string, number]) => {
        const dateKey = moment(item[0]).valueOf();
        const key = this.chartService.sanitizeString(item[1]);

        if (!acc[key]) acc[key] = {};

        if (acc[key][dateKey]) {
          acc[key][dateKey].y = Math.min(acc[key][dateKey].y ?? Infinity, item[2]);
        } else {
          acc[key][dateKey] = { x: dateKey, y: item[2] };
        }
        return acc;
      },
      {} as Record<string, Record<number, ChartDataPoint>>,
    );

    const finalGroupedData: Record<string, ChartDataPoint[]> = {};
    for (const key in groupedData) {
      finalGroupedData[key] = Object.values(groupedData[key]);
    }

    const selectedSeriesNames = this.selection.selected.map((item) =>
      this.chartService.sanitizeString(
        this.isProductTask ? (item as MonitorProductResultModel).seller : (item as MonitorPhraseResultModel).domain,
      ),
    );

    const filteredGroupedData = Object.keys(finalGroupedData)
      .filter((key) => selectedSeriesNames.includes(key))
      .reduce(
        (obj, key) => {
          obj[key] = finalGroupedData[key];
          return obj;
        },
        {} as Record<string, ChartDataPoint[]>,
      );

    const seriesData: ChartSeries[] = Object.entries(filteredGroupedData).map(([name, data]) => ({
      name,
      data,
    }));

    const allYValues = seriesData.flatMap((series) => series.data.map((point) => point.y));
    const maxYValue = allYValues.length > 0 ? Math.max(...allYValues.filter((value): value is number => value !== null)) : 0;
    const yAxisMax = maxYValue * this.yAxisBuffer;

    this.chartOptions = {
      series: this.chartService.normalizeSeriesData(seriesData),
      chart: {
        type: ChartType.line,
        height: 350,
        zoom: { enabled: true },
        toolbar: { show: true },
        animations: { enabled: reloadChart },
      },
      xaxis: {
        type: 'datetime',
        min: minDate,
        max: maxDate,
        labels: { formatter: (value: string) => dateFormatter.format(new Date(parseInt(value, 10))) },
      },
      stroke: { width: 2, curve: 'smooth' },
      yaxis: {
        title: { text: 'Position' },
        max: yAxisMax,
      },
      tooltip: {
        shared: true,
        intersect: false,
        followCursor: false,
        x: { formatter: (val: number) => dateFormatter.format(new Date(val)) },
      },
      markers: { size: 0 },
      fill: { type: 'solid' },
      legend: { position: 'bottom', horizontalAlign: 'center' },
    };

    this.isChartLoading = false;
  }

  private prepareProductDataSet(updateChart: boolean = true) {
    if (this.productResults && this.productResults.data) {
      const { currency, data } = this.productResults;
      this.currency = currency;
      this.taskResults = data;

      this.minmaxData = data.reduce(
        (op, { seller, price }) => {
          const normalizedSeller = seller.replace(/\s/g, '');
          if (!op[normalizedSeller]) {
            op[normalizedSeller] = { key: seller, min: Infinity, max: -Infinity };
          }
          op[normalizedSeller].min = Math.min(op[normalizedSeller].min, price);
          op[normalizedSeller].max = Math.max(op[normalizedSeller].max, price);
          return op;
        },
        {} as Record<string, MinMaxData>,
      );

      const groupedByDates = groupBy(data, 'date');
      const targetDate = this.tableDate
        ? moment(this.tableDate).format('YYYY-MM-DD')
        : moment(maxBy(Object.keys(groupedByDates), (d) => moment(d).valueOf()))!.format('YYYY-MM-DD');

      this.productTableDataSource = sortBy(groupedByDates[targetDate], 'price');

      let sel = this.productTableDataSource.slice(0, 5);
      const myRes = this.productTableDataSource.filter((res: MonitorProductResultModel) => res.is_my_result === true);
      sel = sel.concat(myRes);
      this.selection = new SelectionModel<MonitorProductResultModel | MonitorPhraseResultModel>(true, sel);

      const sortedData = sortBy(data, ['date']);
      this.dataset = sortedData.map((item) => [item.date, item.seller, item.price]);

      updateChart && this.updateCharts(true);
    } else {
      this.productTableDataSource = [];
    }

    this.isTableLoading = false;
    this.markLoaded();
  }

  private preparePhraseDataSet(updateChart: boolean = true) {
    if (this.phraseResults && this.phraseResults.data) {
      const { data } = this.phraseResults;
      this.taskResults = data;

      this.minmaxData = data.reduce(
        (op, { domain, rank_group }) => {
          if (!op[domain]) {
            op[domain] = { key: domain, min: Infinity, max: -Infinity };
          }
          op[domain].min = Math.min(op[domain].min, rank_group);
          op[domain].max = Math.max(op[domain].max, rank_group);

          return op;
        },
        {} as Record<string, MinMaxData>,
      );

      const groupedByDates = groupBy(data, 'date');
      const targetDate = this.tableDate
        ? moment(this.tableDate).format('YYYY-MM-DD')
        : moment(maxBy(Object.keys(groupedByDates), (d) => moment(d).valueOf()))!.format('YYYY-MM-DD');

      this.phraseTableDataSource = sortBy(groupedByDates[targetDate], 'rank_group');

      let sel = this.phraseTableDataSource.slice(0, 5);
      const myResult = this.phraseTableDataSource.filter((res: MonitorPhraseResultModel) => res.is_my_result === true);
      sel = sel.concat(myResult);
      this.selection = new SelectionModel<MonitorProductResultModel | MonitorPhraseResultModel>(true, sel);

      const sortedData = sortBy(data, ['date']);
      this.dataset = sortedData.map((item) => [item.date, item.domain, item.rank_group]);

      updateChart && this.updateCharts(true);
    } else {
      this.phraseTableDataSource = [];
    }
    this.isTableLoading = false;
    this.markLoaded();
  }

  private markLoaded() {
    setTimeout(() => (this.isLoaded = true), 1000);
  }

  tableDateChanged(event: Moment) {
    this.tableDate = event;
    this.getResults(this.tableDate, this.tableDate, false);
  }
}
