import { FilterGroupModel, FilterInStorageModel, FiltersGroupParamsInterface, FiltersModel } from '../filters/models/filter.model';
import { ItemModel } from '../item.model';
import { ColumnGroupModel } from '../table-display/columns-switch/columns/ColumnGroup.model';
import { ColumnModel, ColumnsStorageSetModel } from '../table-display/columns-switch/columns/column.model';
import { CustomButtonInterface, CustomButtonPerItemInterface } from './CustomButton.model';

export interface ActionColumnInterface {
  type: 'select'; // @TODO: Will there be more types?
}

export enum DefaultTabs {
  ALL = 'all',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface TabsToolConfig {
  active?: boolean;
  defaultTabs?: DefaultTabs[];
  filterSet?: FilterInStorageModel;
  filterGroup: FilterGroupModel[];
  filterManualChanges: boolean | null;
  name: string;
  position: 'left' | 'right';
  visible?: boolean;
}

export interface ConfigMemory {
  columnsSets?: ColumnsStorageSetModel[];
  filterSets?: FilterInStorageModel[];
  filtersRecent?: FilterInStorageModel[];
  tabs?: TabsToolConfig[];
}

export interface PaginatorInterface {
  isGoToPageHidden?: boolean;
  isItemsPerPageHidden?: boolean;
}

export class TableConfigurationInterface {
  tools: {
    // searchBy?: string; // quick search by which param
    actionButtons?: ToolInterface; // buttons container in item line
    aggregationSelected?: boolean;
    aggregationTotal?: boolean;
    choosingItems?: boolean; // choosing Items tool active
    column?: boolean; // able to change columns
    detailsBtn?: ToolInterface;
    duplicate?: ToolInterface; // able to duplicate item emits itemDuplicated
    duplicateBy?: ToolByColumnInterface; // tool to quick duplicate item by changing one column
    duplicateParamChange?: { columnName: string; callback: (value: any, item: ItemModel) => any };
    editInPopup?: ToolInterface; // able to edit item in popup
    expandable?: boolean;
    filter?: boolean; // able to filter
    filterAdvancedGroup?: boolean; // able to create more group than one?
    filterAdvancedLogic?: boolean; // able change logcial condition or/and?
    grouping?: boolean; // able to group items
    multiDuplicate?: boolean; // able to multi duplicate item emits itemsMultiDuplicated
    quickFilters?: string[];
    removing?: ToolInterface; // able to remove item emits dataToRemove
    rule?: boolean; // able to create rules
    select?: boolean; // select tool active
    tabs?: TabsToolConfig[];
    filterManualChanges?: boolean;
  } = {};

  actionColumn?: ActionColumnInterface;
  actionsButtonSlot?: CustomButtonPerItemInterface[];
  activeFilters?: FiltersModel;
  columnGroups?: ColumnGroupModel[] = [];
  columns: { [key: string]: ColumnModel } = {};
  configuration: { storageName: string } = { storageName: '' };
  displayedColumns: string[] = [];
  groupedBy?: string[];
  inactiveStateDependencyColumn?: string;
  initFilters?: FiltersModel;
  initFiltersParams?: FiltersGroupParamsInterface;
  itemRowStyle?: any;
  itemsPerPage?: number;
  mainToolbarSlot?: CustomButtonInterface[];
  memory?: ConfigMemory;
  navigationColumn?: string;
  paginator?: PaginatorInterface;
  readOnly?: boolean;
  requiredFields?: string[];
  selectedGloballyToolbarSlot?: CustomButtonInterface[];
  selectedToolbarDropdown?: { translationKey: string };
  selectedToolbarSlot?: CustomButtonInterface[];
  sorting?: { active: string; direction: 'asc' | 'desc' } | null;
  newItemRow?: {
    columnName?: string;
    translateKey: {
      mainLevel: string;
      subLevel: string;
    };
  };
}

export interface ToolInterface {
  activeAlways?: boolean;
  dependsOnParam?: string;
  position?: 'icons' | 'menu';
  singleChange?: boolean;
}

interface ToolByColumnInterface {
  activeAlways?: boolean;
  columnBy?: string;
  dependsOnParam?: string;
}
